<template>
  <div class="recommendations-item">
    <div class="recommendations-item__container h-100">
      <router-link
        class="text-decoration-none"
        :to="{ name: 'Restaurant', params: { name: data.name, id: data.slug } }"
      >
        <div class="row h-100">
          <div class="recommendations-item__image col-xl-4 position-relative">
            <img class="img-fluid image" :src="data.image" />
            <div class="restaurant-item-flag-dog" v-if="data.month">
              <div class="restaurant-item-flag-title">
                
                <div class="restaurant-item-flag-title-year text-start">
                    FID
                  </div>
                  <div class="restaurant-item-flag-title-year text-start">
                    REST
                  </div>
              </div>
              <span>
                <IconDog />
              </span>
            </div>
          </div>
          <div class="recommendations-item__info align-left col-xl-5">
            <div class="recommendations-item__info-container">
              <div class="recommendations-item__info-title">
                {{ data.name }}
              </div>
              <div class="recommendations-item__info-address">
                {{ data.addresssec }}
              </div>
            </div>
            <div class="recommendations-item__info-phone">
              <span class="recommendations-item__info-phone-icon"
                ><PhoneIcon /></span
              >{{ data.phone }}
            </div>
          </div>
          <div class="recommendations-item__likes col-xl-3">
            <div class="recommendations-item__likes-counter">
              <LikesCounter :counter="data.likes" />
            </div>
            <div class="recommendations-item__likes-quote align-left">
              <p v-if="!special" >{{ data.comment }}</p>
               <p v-if="special" v-html="$filters.withBrTags($filters.trim(data.comment,100,'...'))"></p>

            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import LikesCounter from "./LikesCounter.vue";
import PhoneIcon from "./icons/PhoneIcon.vue";
import IconDog from "../components/icons/FidDog.vue"
export default {
  name: "Restaurant Item",
  components: {
    LikesCounter,
    PhoneIcon,
    IconDog,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    }, 
    special: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // This is only data for design! and test || TODO -- getting from service
    return {
      image:
        "https://media-cdn.tripadvisor.com/media/photo-s/07/be/65/98/escondidinho-do-barredo.jpg",
      title: "Restaurante Lés-a-Lés",
      address: "Braga, Sé",
      phone: "966 000 000",
      likes: 1500,
      quote: "Lorem ipsum dolor sit amet,consectetuer adipiscing elit",
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/_variables";
.restaurant-item-flag-dog{
    right: 12%;
}
.recommendations-item {
  .recommendations-item__container {
    border-radius: 50px;
    background-color: white;
    @media (max-width: 1199.98px) {
      border-radius: 20px;

      .row > div:not(:first-child) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  .recommendations-item__image {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 20px;
      max-height: 190px;
      @media (max-width: 1199.98px) {
        border-radius: 20px;
      }
    }
  }

  .recommendations-item__likes-counter {
    @media (max-width: 1199.98px) {
      padding: 20px 0;
    }
  }

  .recommendations-item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media (max-width: 1199.98px) {
      margin-top: 25px;
    }

    .recommendations-item__info-title {
      font-family: "AzoSans-Bold";
      font-size: 22px;
      line-height: 37px;
      color: $primary-grey-color;

      @media (max-width: 1199.98px) {
        font-size: 26px;
        line-height: 30px;
      }
    }

    .recommendations-item__info-address {
      font-family: "AzoSans-Bold";
      font-size: 20px;
      line-height: 20px;
      color: $primary-grey-color;
    }

    .recommendations-item__info-phone {
      font-size: 20px;
      line-height: 20px;
      color: $primary-grey-color;
      display: flex;
      align-items: center;

      @media (max-width: 1199.98px) {
        padding: 20px 0;
        border-bottom: 1px solid $second-grey-color;
      }

      .recommendations-item__info-phone-icon {
        width: 28px;
        height: 28px;
        background-color: $primary-red;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        margin-right: 15px;
      }
    }
  }

  .recommendations-item__likes {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .recommendations-item__likes-quote {
      quotes: "“" "”";
      font-size: 18px;
      line-height: 18px;
      position: relative;
      display: flex;

      &::before {
        font-family: sans-serif;
        content: open-quote;
        font-size: 120px;
        color: #e6041e;
        padding-top: 38px;
      }

      p {
        padding-left: 12px;
      }
    }
  }
}
</style>