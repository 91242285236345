<template>
  <div class="restaurant-item">
    <router-link
      class="text-decoration-none"
      :to="{ name: 'Restaurant', params: { name: data.title, id: data.slug } }"
    >
      <div class="restaurant-item-image position-relative">
        <img class="img-fluid image" :class="data.defaultimage?'special':''" :src="data.image" />
        <div class="restaurant-item-title">
          {{ data.name }}
        </div>

        <div class="restaurant-item-flag-dog" v-if="data.fidrest">
       
          <div  class="restaurant-item-flag-title">
            
            <div class="restaurant-item-flag-title-year text-start">
                    FID
                  </div>
                  <div class="restaurant-item-flag-title-year text-start">
                    REST
                  </div>
          </div>
          <span>
            <IconDog />
          </span>
        </div>
      </div>
    </router-link>
    <div v-if="special" class="top-approve approve-rest-list">
      <ApproveBtn :approveStatus="data.approved" @update="actionApprove"></ApproveBtn>
    </div>
    <div :class="special ? 'special rest-item' : ''">
      <div class="restaurant-item-info align-left">
        <div class="restaurant-item-info_address">
          {{ data.addresssec }}
        </div>
        <div class="restaurant-item-info_phone">
          <a :href="`tel:${phoneNumber(data.phone,true)}`">{{ phoneNumber(data.phone,false) }}</a>
        </div>
      </div>
      <div class="restaurant-item-info_likes">
        <LikesCounter :counter="data.likes" />
      </div>
    </div>
  </div>
  <div v-if="special" class="bottom-approve approve-rest-list">
    <ApproveBtn :approveStatus="data.approved" @update="actionApprove"></ApproveBtn>
  </div>
</template>

<script>
import LikesCounter from "./LikesCounter.vue";
import IconDog from "../components/icons/FidDog.vue";
import ApproveBtn from "../components/ApproveBtn.vue";
export default {
  name: "Restaurant Item",
  components: {
    LikesCounter,
    IconDog,
    ApproveBtn,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
    special: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    phoneNumber(phone,full) {
      phone = phone.replace(/\s/g, '');
      if(full) return phone;
      phone = phone.match(/.{1,3}/g);
      phone = phone.join(" "); //returns 123-456-789
      return phone;
    },
    actionApprove() {
      if (!window.authUser) {
        this.$router.push({ name: "Home", hash: "#login" });
      }else{
         // For adding the token to axios header (add this only one time).
        var token = document.head.querySelector('meta[name="csrf-token"]');
        window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
        axios.post(route("like"),{user:window.authUser.id,rest:this.data.id}).then((response)=>{
              this.data.approved = response.data;
              if(this.data.approved){
                this.data.likes = this.data.likes + 1;
              }else{
                this.data.likes = this.data.likes - 1;
              }
              
          }).catch((error)=>{
              console.log(error.response.data)
          });
      }
    },
  },
  data() {
    // This is only data for design! and test || TODO -- getting from service
    return {
      image:
        "https://media-cdn.tripadvisor.com/media/photo-s/07/be/65/98/escondidinho-do-barredo.jpg",
      title: "Restaurante Lés-a-Lés",
      address: "Braga, Sé",
      phone: "966 000 000",
      likes: 0,
      approveStatus: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables";

.restaurant-item {
  .restaurant-item-image {
    position: relative;
    overflow: hidden;

    img {
      filter: blur(5px);
      transform: scale(1.1);
      object-fit: cover;
      height: 410px;
      width: 100%;
    object-position: 49% 0px;
     
      @media (max-width: 1399px) {

      height: 360px;
      } 
      @media (max-width: 767.98px) {
        height: 250px;
      }
         
      @media (max-width: 375px) {
        height: 190px;
      }
      

      &.special{
        filter: none;
        transform: scale(1);

      }
    }

    .restaurant-item-title {
      position: absolute;
      left: 0px;
      bottom: 0px;
      font-family: "AzoSans-Bold";
      font-size: 18px;
      line-height: 22px;
      color: white;
      padding: 15px 10px;
      max-width: 100%;
      @media (max-width: 1199.98px) {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  .restaurant-item-info_address {
    font-family: "AzoSans-Bold";
    font-size: 22px;
    line-height: 22px;
    color: $primary-grey-color;
    margin-bottom: 10px;
    @media (max-width: 1199.98px) {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .restaurant-item-info_phone {
    a {
      font-family: "AzoSans-Regular";
      font-size: 20px;
      line-height: 20px;
      color: $primary-grey-color;
      text-decoration: none;
      @media (max-width: 1199.98px) {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .restaurant-item-info {
    margin-top: 25px;
    margin-bottom: 15px;
    @media (max-width: 1199.98px) {
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }
}
</style>