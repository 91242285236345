<template>
    <HeaderFullNew v-if="isHome"></HeaderFullNew>
    <HeaderMin v-if="!isHome"></HeaderMin>
    <router-view/>
    <Footer></Footer>
</template>

<script>
import HeaderFullNew from "./components/HeaderFullNew.vue"
import HeaderMin from "./components/HeaderMin.vue"
import Footer from "./components/Footer.vue"

export default {
  name: 'App',
  components: {
    HeaderFullNew,
    Footer,
    HeaderMin
  },
  computed: {
    isHome() {
        return this.$route.name === 'Home';
    }
  }
}
</script>

<style lang="scss">

</style>
