<template>
  <div class="restaurant-list">
    <div class="restaurant-list_header">
      <div class="container">
        <div class="row">
          <div class="restaurant-list_back col-md-3">
            <router-link class="btn-fid-grey btn-back" :to="{ name: 'Home' }">
              Voltar
            </router-link>
            <a class="restaurant-list_filter-btn btn-fid-red collapsed d-flex d-md-none" data-bs-toggle="collapse"
              href="'#filtersTags" role="button" aria-expanded="true" aria-controls="filtersTags">
              <span class="filter-check_content-filter-title">Filtrar</span>
              <span class="filter-check_arrow">
                <ArrowWhite />
              </span>
            </a>
          </div>
          <div class="restaurant-list_search col-md-9">
            <div class="row h-100 align-items-center">
              <div class="restaurant-list_search-tags col-md-8" v-if="(localCheckedValues.length > 0) || (fidrest)">
                <div class="restaurant-list_search-tags-container">
                  <div class="restaurant-list_search-tags-item" v-if="fidrest">
                    Fidrest
                    <span @click="removeTagFilter('fidrest', 0)">
                      <CloseIcon />
                    </span>
                  </div>
                  <div class="restaurant-list_search-tags-item" v-for="(tag, index) in localCheckedValues" :key="index">
                    {{ tag.name }}
                    <span @click="removeTagFilter(tag, index)">
                      <CloseIcon />
                    </span>
                  </div>
                </div>
              </div>
              <div class="restaurant-list_search-btn col-md-4 d-none d-md-inline-flex"
                :class="[(localCheckedValues.length > 0) || (fidrest) ? '' : 'offset-md-8']">
                <div ref="searchContainer" class="collapse collapse-horizontal search-inbox in width"
                  id="collapseSearchInput">
                  <input type="text" v-model="searchText" class="form-control" placeholder="Ex. Nome de Restaurante"
                    aria-label="Ex. Nome de Restaurante" aria-describedby="inputGroup-sizing-lg"
                    v-on:keyup.enter="SearchAction" />
                </div>
                <button @click="clickSearch" class="restaurant-list_search-btn-submit btn-fid-grey">
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="restaurant-list_content">
      <div class="container border-right">
        <div class="row">
          <div class="restaurant-list_content-fitlers col-md-3 collapse" id="filtersTags">
            <div class="restaurant-list_content-fitlers-container row">
              <div class="restaurant-list_content-filter-item py-0">
                <div class="filter-check_content-fitlers-content">
                  <div class="filter-check_content-fitlers-check">
                    <label class="form-check-label labelcustom" for="fidrest">
                      FidRests
                    </label>
                    <input class="form-check-input-reverse" type="checkbox" :checked="fidrest" id="fidrest"
                      @change="updateChecked('fidrest')" />
                  </div>
                </div>
              </div>
              <div class="restaurant-list_content-filter-item" v-for="(item, index) in localtestOptions" :key="index">
                <FilterCheck :name="item.name" :options="item.options" :indexCollapse="'collapse_' + index"
                  @update="updateChecked"></FilterCheck>
              </div>
            </div>
            <div class="restaurant-list_search-btn-filter row d-flex d-md-none">
              <div ref="searchContainerFilter" class="collapse collapse-vertical search-inbox in width"
                id="collapseSearchInputFilter">
                <input type="text" v-model="searchText" class="form-control" placeholder="Ex. Nome de Restaurante"
                  aria-label="Ex. Nome de Restaurante" aria-describedby="inputGroup-sizing-lg"
                  v-on:keyup.enter="SearchAction" />
              </div>
              <div class="searchBtnContainer">
                <button @click="clickSearchFilter" class="restaurant-list_search-btn-submit btn-fid-grey">
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
          <div class="restaurant-list_content-list col-md-9">
            <div class="row g-4">
              <div class="restaurant-list_content-list-item col-6 col-md-4 col-lg-4"
                v-for="(restaurant, index) in localTestListRest" :key="index">
                <RestaurantItem :special="special" :data="restaurant"></RestaurantItem>
              </div>
            </div>
            <div class="row" v-if="hasMoreResults">
              <button @click="clickMore" class="restaurant-list_more-btn btn-fid-red">
                <PlusIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="restaurant-list_btn-back-bottom">
        <router-link :to="{ name: 'Home' }" class="w-100 mt-100 btn btn-primary btn-bold">Voltar à página
          principal</router-link>
      </div>
    </div>
    <div class="mt-100">
      <ImageFull class-extra="parsley-back" :imageUrl="imageUrlBottom" />
    </div>
  </div>
</template>

<script>
  import FilterCheck from "../components/FilterCheck.vue";
  import RestaurantItem from "../components/RestaurantItem.vue";
  import SearchIcon from "../components/icons/SearchIcon.vue";
  import CloseIcon from "../components/icons/CloseIcon.vue";
  import PlusIcon from "../components/icons/PlusIcon.vue";
  import Arrow from "../components/icons/Arrow.vue";
  import ArrowWhite from "../components/icons/Arrowwhite.vue";
  import ImageFull from "../components/ImageFull.vue";
  import axios from "axios";
  export default {
    name: "Restaurants List",
    props: {},
    components: {
      FilterCheck,
      RestaurantItem,
      SearchIcon,
      CloseIcon,
      Arrow,
      ArrowWhite,
      ImageFull,
      PlusIcon,
    },
    data() {
      return {
        imageUrlBottom: require("../images/image-bottom2.jpg").default,
        searchText: this.$route.query.hasOwnProperty("search")
          ? this.$route.query.search
          : "",
        updateChecks: 0,
        special: true,
        fidrest: false,
        start: 0,
        hasMoreResults: true, //change this if has more or not results
        localtestOptions: [

          {
            name: "Região",
            options: [
              {
                name: "Norte",
                value: "Norte",
                type: "region",
                checked: false,
              },
              {
                name: "Centro",
                value: "Centro",
                type: "region",
                checked: false,
              },
              {
                name: "Sul",
                value: "Sul",
                type: "region",
                checked: false,
              },
              {
                name: "Açores",
                value: "Açores",
                type: "region",
                checked: false,
              },
              {
                name: "Madeira",
                value: "Madeira",
                type: "region",
                checked: false,
              },
              {
                name: "Todas",
                value: "Todas",
                type: "region",
                checked: false,
              },
            ],
          },
          {
            name: "Especialidade",
            options: [
              {
                name: "Portuguesa",
                value: "Portuguesa",
                type: "spec",
                checked: false,
              },
              {
                name: "Internacional",
                value: "Internacional",
                type: "spec",
                checked: false,
              },
              {
                name: "Vegan/Vegetariana",
                value: "Vegan-Vegetariana",
                type: "spec",
                checked: false,
              },
              {
                name: "Outros",
                type: "spec",
                value: "Outros",
                checked: false,
              },
              {
                name: "Todas",
                value: "Todas",
                type: "spec",
                checked: false,
              },
            ],
          },
          {
            name: "Preço",
            options: [
              {
                name: "Até 25€",
                value: "25",
                type: "price",
                checked: false,
              },
              {
                name: "25€ a 50€",
                value: "50",
                type: "price",
                checked: false,
              },
              {
                name: "50€ a 75€",
                value: "75",
                type: "price",
                checked: false,
              },
              {
                name: "75€ a 100€",
                value: "100",
                type: "price",
                checked: false,
              },
              {
                name: "Superior a 100€",
                value: "100more",
                type: "price",
                checked: false,
              },
              {
                name: "Todos",
                value: "Todos",
                type: "price",
                checked: false,
              },
            ],
          },
          {
            name: "Votos",
            options: [
              {
                name: "Mais votos",
                value: "desc",
                type: "sort",
                checked: false,
              },
              {
                name: "Menos votos",
                value: "asc",
                type: "sort",
                checked: false,
              },
            ],
          },
        ],
        localCheckedValues: [],
        localTestListRest: [],
      };
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        var region = "";
        var spec = "";
        var price = "";
        var sort = "";
        var reserv = "";
        if (this.localCheckedValues.length > 0) {
          for (var y = 0; y < this.localCheckedValues.length; y++) {
            var type = this.localCheckedValues[y].type;
            var val = this.localCheckedValues[y].value;
            eval(type + " = " + type + ' + "' + val + '";');
          }
        }
        axios
          .get(
            route("search", {
              start: this.start,
              step: 6,
              search: this.searchText,
              region: region,
              spec: spec,
              fidrest: this.fidrest,
              price: price,
              sort: sort,
              reserv: reserv,
              id: window.authUser ? window.authUser.id : false,
            })
          )
          .then((response) => {
            if (this.start > 0) {
              this.localTestListRest.push(...response.data.data);
            } else {
              this.localTestListRest = response.data.data;
            }
            this.hasMoreResults = response.data.more;
          });
      },
      SearchAction() {
        if (this.searchText != "") {
          this.fetchData();
        }
      },
      updateFilterValues() {
        var self = this;
        for (var t = 0; t < this.localtestOptions.length; t++) {
          for (var i = 0; i < this.localtestOptions[t].options.length; i++) {
            var opt = $.extend(true, {}, this.localtestOptions[t].options[i]);
            if (this.localCheckedValues.length > 0) {
              for (var y = 0; y < this.localCheckedValues.length; y++) {
                if (
                  opt.value == this.localCheckedValues[y].value &&
                  opt.type == this.localCheckedValues[y].type
                ) {
                  opt.checked = true;
                  self.localtestOptions[t].options[i] = opt;
                  break;
                } else {
                  opt.checked = false;
                  self.localtestOptions[t].options[i] = opt;
                }
              }
            } else {
              opt.checked = false;
              self.localtestOptions[t].options[i] = opt;
            }
          }
        }
      },
      updateChecked(val) {
        if (val == 'fidrest') {
          this.fidrest = !this.fidrest;
        } else {
          if (this.localCheckedValues.length > 0 && val.length > 0) {
            for (var y = 0; y < val.length; y++) {
              var founded = null;
              for (var i = 0; i < this.localCheckedValues.length; i++) {
                if (val[y].value == this.localCheckedValues[i].value) {
                  founded = val[y];
                  break;
                }
              }

              if (founded == null && val[y].checked) {
                this.localCheckedValues.push(val[y]);
              } else if (founded != null) {
                for (var i = 0; i < this.localCheckedValues.length; i++) {
                  if (founded.value == this.localCheckedValues[i].value && !founded.checked) {
                    this.localCheckedValues.splice(i, 1);
                    break;
                  }
                }
              }
            }
          } else {
            for (var x = 0; x < val.length; x++) {
              if (val[x].checked) {
                this.localCheckedValues.push(val[x]);
              }
            }
          }
        }
        this.updateFilterValues();
        this.updateDataByTags(); // call service to update results
      },
      removeTagFilter(tag, index) {
        if(tag == 'fidrest'){
          this.fidrest = false;
        }else{
        this.localCheckedValues.splice(index, 1);
       
        this.updateFilterValues();
        var newArr = JSON.parse(JSON.stringify(this.localtestOptions)); // deep clone
        this.localtestOptions = newArr;
        this.updateChecks += 1;
      }
        this.updateDataByTags(); // call service to update results
        
      },
      updateDataByTags() {
        this.fetchData();
      },
      clickSearch() {
        if ($(this.$refs.searchContainer).hasClass("show")) {
          this.SearchAction();
          $(this.$refs.searchContainer).collapse("hide");
        } else {
          $(this.$refs.searchContainer).collapse("show");
        }
      },
      clickSearchFilter() {
        if ($(this.$refs.searchContainerFilter).hasClass("show")) {
          this.SearchAction();
          $(this.$refs.searchContainerFilter).collapse("hide");
        } else {
          $(this.$refs.searchContainerFilter).collapse("show");
        }
      },
      clickMore() {
        // method for load more results from api

        this.start += 6;
        this.fetchData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/_variables";

  .fidrest-title {

    &,
    span {
      font-family: "AzoSans-Light";
      font-size: 42px;
      line-height: 42px;
      text-transform: uppercase;
      text-align: right;
    }

    color: $second-grey-color;

    span {
      color: $primary-red;
      font-family: "AzoSans-Bold";
    }
  }

  .restaurant-list_back {
    padding-top: 42px;
    padding-bottom: 42px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 425px) {
      font-size: 36px;
      line-height: 36px;
      border-right: none;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .restaurant-list_content-fitlers-container {
    @media (max-width: 425px) {
      border-top: 1px solid $primary-grey-color;
    }
  }

  .restaurant-list_btn-back-bottom {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .restaurant-list_more-btn {
    width: 73px;
    height: 73px;
    border: none;
    border-radius: 73px;
    margin: 54px auto;
  }

  .filter-check_content-fitlers-check {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    >label {
      flex: 1;
    }
  }

  .labelcustom {
    font-family: "AzoSans-Bold";
    font-size: 18px;
    line-height: 45px;
    color: #707070;
    text-decoration: none;
    flex: 1;
  }

  .btn-back {
    border: none;
    text-decoration: none;
    padding: 17px 0px;
    font-family: "AzoSans-Black";
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    border-radius: 8px;
    display: block;
    text-align: center;
    width: 100%;
    max-width: 170px;
    justify-content: center;

    @media (max-width: 425px) {
      max-width: 112px;
      padding: 12px;
    }
  }

  .restaurant-list_filter-btn {
    border: none;
    text-decoration: none;
    padding: 17px 0px;
    font-family: "AzoSans-Black";
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    max-width: 170px;
    justify-content: space-between;
    align-items: center;

    &:not(.collapsed) {
      .filter-check_arrow {
        transform: rotate(180deg);
      }
    }

    max-width: 112px;
    padding: 12px;
    display: block;
    position: relative;
    text-align: left;

    span {
      color: #fff;
      font-family: "AzoSans-Black";
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .filter-check_arrow {
      margin-top: -2px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .restaurant-list_search-btn-submit {
    border: none;
    text-decoration: none;
    border-radius: 8px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    max-width: 100px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .restaurant-list_search-tags {
    @media (max-width: 425px) {
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid $primary-grey-color;
    }
  }

  .restaurant-list_search-tags-container {
    display: flex;
    justify-content: center;

    .restaurant-list_search-tags-item {
      background-color: $primary-red;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "AzoSans-Bold";
      font-size: 20px;
      line-height: 24px;
      color: white;
      padding: 0 12px;
      border-radius: 8px;
      margin-right: 10px;

      &:last-child {
        margin-right: unset;
      }

      span {
        padding-left: 10px;
        cursor: pointer;
      }

      @media (max-width: 425px) {
        height: 40px;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .restaurant-list_search {
    border-left: 1px solid $primary-grey-color;

    @media (max-width: 425px) {
      border-left: none;
    }
  }

  .restaurant-list_content-list {
    border-left: 1px solid $primary-grey-color;
    padding: 50px;

    @media (max-width: 425px) {
      padding: inherit;
      border-left: none;
      border-top: 1px solid $primary-grey-color;
      padding-top: 30px;
    }

    @media (max-width: 768px) {
      padding: 10px;
      overflow: hidden;
    }
  }

  .restaurant-list_content {
    border-bottom: 1px solid $primary-grey-color;

    @media (max-width: 768px) {
      >.border-right {
        border-right: 0px !important;
      }
    }
  }

  .restaurant-list_header {
    border-bottom: 1px solid $primary-grey-color;

    @media (max-width: 425px) {
      border-bottom: 0px;
    }
  }

  .restaurant-list_content-filter-item {
    padding: 5px 10%;

    @media (max-width: 768px) {
      padding: 0px 10px 0px 0px;
    }

    @media (max-width: 425px) {
      padding: 0px 15px;
      border-bottom: 1px solid $primary-grey-color;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .restaurant-list_search-btn {
    display: inline-flex;
    justify-content: flex-end;
    align-content: center;

    input {
      height: 50px;
      margin-right: 10px;
      margin-bottom: 0;
    }

    button {
      margin-left: 10px;
    }
  }

  .restaurant-list_search-btn-filter {
    justify-content: center;
    align-content: center;
    flex-direction: column;
    border-top: 1px solid $primary-grey-color;
    padding-top: 30px;
    padding-bottom: 30px;

    input {
      height: 50px;
      margin-bottom: 10px;
    }

    .restaurant-list_search-btn-submit {
      max-width: inherit;
    }
  }
</style>