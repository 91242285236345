<template>
  <div class="suggest-restaurant mb-4" id="log">
    <div class="container">
      <div class="suggest-restaurant__initial" v-if="authUser" id="sugestao">
    <div class="row">
        <div class="col-lg-8 offset-lg-2">
            <div class="suggestion-title text-center text-md-start">
                <h1 class="d-md-inline-block d-block px-4 px-md-0">Uma sugestão <br><strong>pronta a servir.</strong></h1>
                <svg class="quotesvg d-inline-block px-md-3 mt-2 ml-2" xmlns="http://www.w3.org/2000/svg" width="105.84" height="92.88" viewBox="0 0 105.84 92.88">
                    <path id="Path_15240" data-name="Path 15240" d="M63.72-164.16v-39.96H42.48a87.746,87.746,0,0,1,.9-12.42,36.838,36.838,0,0,1,3.42-11.34,23.057,23.057,0,0,1,6.48-8.1,16,16,0,0,1,10.08-3.06v-18a34.939,34.939,0,0,0-17.64,4.32A36.84,36.84,0,0,0,33.3-241.2a51.607,51.607,0,0,0-7.2,16.74,85.162,85.162,0,0,0-2.34,20.34v39.96Zm65.88,0v-39.96H108.36a87.746,87.746,0,0,1,.9-12.42,36.837,36.837,0,0,1,3.42-11.34,23.057,23.057,0,0,1,6.48-8.1,16,16,0,0,1,10.08-3.06v-18a34.939,34.939,0,0,0-17.64,4.32A36.84,36.84,0,0,0,99.18-241.2a51.607,51.607,0,0,0-7.2,16.74,85.162,85.162,0,0,0-2.34,20.34v39.96Z" transform="translate(129.6 -164.16) rotate(180)" fill="#e6041e" />
                </svg>
            </div>
            <div class="quote mb-3 row mt-4">
                <div class="col-12 text-center text-md-start">
                    Conhece um restaurante que merece o seu selo de aprovação? <br class="d-none d-md-inline-block">
                    Preencha os campos abaixo para o adicionarmos ao cardápio.
                </div>
            </div>
            <form method="POST" :action="suggestion" class="row my-lg-5 my-md-0 pb-lg-2" enctype="multipart/form-data">
                <input type="hidden" name="_token" :value="csrf">
                <div class="form-group col-md-12 ">
                    <input id="name" type="text" placeholder="Nome do restaurante *" class="bold form-control" name="name" value="">
                </div>
                <div class="form-group col-md-4 ">
                    <select id="region" class="form-control" name="region" required>
                        <option value="">Região*</option>
                        <option value="Norte">Norte</option>
                        <option value="Centro" >Centro</option>
                        <option value="Sul">Sul</option>
                        <option value="Açores">Açores</option>
                        <option value="Madeira">Madeira</option>
                        <option value="Todas">Todas</option>
                    </select>
                  
                </div>
                <div class="form-group col-md-4 ">
                    <select id="specialty" class="form-control" name="specialty" required>
                        <option value="">Especialidade*</option>
                        <option value="Portuguesa">Portuguesa</option>
                        <option value="Internacional">Internacional</option>
                        <option value="Vegan-Vegetariana">Vegan/Vegetariana</option>
                        <option value="Outros">Outros</option>
                        <option value="Todas">Todas</option>
                    </select>
                </div>
                <div class="form-group col-md-4 ">
                    <select id="avgcost" class="form-control" name="avgcost" required>
                        <option value="">Valor médio /pessoa*</option>
                        <option value="25">Até 25€</option>
                        <option value="50">25€ a 50€</option>
                        <option value="75">50€ a 75€</option>
                        <option value="100">75€ a 100€</option>
                        <option value="100more">Superior a 100€</option>
                        <option value="Todos">Todos</option>
                    </select>
                   
                </div>
                <div class="form-group col-md-12 ">
                    <input id="address" type="text" placeholder="Morada *" class="form-control " name="address" value="" required>
                </div>
                <div class="form-group col-md-12 ">
                    <input id="city" type="text" placeholder="Cidade *" class="form-control " name="city" value="" required>
                </div>
                <div class="form-group col-md-6 ">
                    <input id="phone" type="text" placeholder="Número Telefone *" class="form-control" name="phone" value="" required>
                    
                </div>
                <div class="form-group col-md-6 ">
                    <input id="site" type="text" placeholder="Site" class="form-control " name="site" value="">
                   
                </div>
                <div class="form-group col-md-12 ">
                    <input id="comment" type="text" placeholder="Breve comentário *" class="form-control " name="comment" value="" required>
                   
                </div>
                <div class="form-group form-group-file col-md-8">
                    <input type="file" id="photo" name="photo" class="inputfile">
                    <label for="photo" class="row m-0">
                        <div id="photo-name" class="file-box col-md-9">Submeter fotografia (Proporção Recomendada: 1080x1920)</div>
                        <span class="file-button btn btn-dark px-4 col-md-3">
                            Selecionar
                        </span>
                    </label>
                </div>
                <div class="form-group col-md-4 ">
                    <button type="submit" class="w-100 btn btn-primary btn-bold">
                        Submeter
                    </button>
                </div>
                <div class="col-12 register-link">
                    <sup>*</sup> Campos obrigatórios
                </div>

            </form>
        </div>
</div>
      </div>
      <p id="login"></p>
      <div class="suggest-restaurant__login" v-if="!authUser">
        <div class="row">
          <div class="col-md-10 offset-md-1" v-if="!reqPass">
            <h1>Sugira-nos <strong>restaurantes.</strong></h1>
            <div class="quote mb-5 row mt-1">
              <div class="col-md-10 col-12">
                Chegou o momento de ativarmos o nosso paladar. Como? Através do Mesa Reservada que reúne os melhores restaurantes portugueses, sugeridos por si. O seu contributo é essencial!
                <br />
                <br />Conhece um restaurante que merece o seu selo de aprovação?
                <br />Para o adicionarmos ao cardápio, faça login com os seus
                dados.
              </div>
              <div class="col-md-2 col-2">
                <svg
                  class="px-md-3 mt-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="105.84"
                  height="92.88"
                  viewBox="0 0 105.84 92.88"
                >
                  <path
                    id="Path_15240"
                    data-name="Path 15240"
                    d="M63.72-164.16v-39.96H42.48a87.746,87.746,0,0,1,.9-12.42,36.838,36.838,0,0,1,3.42-11.34,23.057,23.057,0,0,1,6.48-8.1,16,16,0,0,1,10.08-3.06v-18a34.939,34.939,0,0,0-17.64,4.32A36.84,36.84,0,0,0,33.3-241.2a51.607,51.607,0,0,0-7.2,16.74,85.162,85.162,0,0,0-2.34,20.34v39.96Zm65.88,0v-39.96H108.36a87.746,87.746,0,0,1,.9-12.42,36.837,36.837,0,0,1,3.42-11.34,23.057,23.057,0,0,1,6.48-8.1,16,16,0,0,1,10.08-3.06v-18a34.939,34.939,0,0,0-17.64,4.32A36.84,36.84,0,0,0,99.18-241.2a51.607,51.607,0,0,0-7.2,16.74,85.162,85.162,0,0,0-2.34,20.34v39.96Z"
                    transform="translate(129.6 -164.16) rotate(180)"
                    fill="#e6041e"
                  />
                </svg>
              </div>
            </div>
            <form method="POST" action="login" class="row my-5 my-md-0">
              <input type="hidden" name="_token" :value="csrf">
              <div class="form-group col-md-5">
                <div class="">
                  <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    class="bold form-control"
                    name="email"
                    value=""
                    required
                    autocomplete="email"
                  />
                </div>
              </div>

              <div class="form-group col-md-5">
                <div class="">
                  <input
                    id="password"
                    type="password"
                    placeholder="Password"
                    class="bold form-control"
                    name="password"
                    required
                    autocomplete="current-password"
                  />
                </div>
              </div>

              <div class="form-group col-md-2">
                <div class="">
                  <button type="submit" class="w-100 btn btn-primary btn-bold">
                    Login
                  </button>
                </div>
              </div>
              <div class="col-12 text-md-end">
                <button class="btn btn-link" @click="changePass()"> Esqueceu-se da password? </button>
              </div>
              <div class="col-12 register-link mt-4">
                Ainda não está registado? <a :href="reg">Registe-se aqui</a> para
                sugerir restaurantes.
              </div>
            </form>
          </div>
          <div class="col-md-10 offset-md-1" v-if="reqPass">
            <h1 class="mb-4">Recuperar <strong>password</strong></h1>

            <form method="POST" action="password/email" class="mb-5">
            <input type="hidden" name="_token" :value="csrf">
                <div class="form-group row">
                    <div class="col-12">
                        <input id="email" placeholder="Email" type="email" class="form-control" name="email" value="" required autocomplete="email" autofocus>
                    </div>
                </div>

                <div class="form-group row mb-0">
                    <div class="col-12 text-right">
                        <button type="submit" class="btn btn-primary btn-bold">
                            Enviar acesso para reiniciar senha
                        </button>
                    </div>
                </div>
            </form>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputSelect from "./InputSelect.vue";
export default {
  name: "Suggest restaurant",
  components: {
    InputSelect,
  },
  data() {
    return {
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      showInitial: true,
      showLogin: false,
      suggestion: route('suggestion'),
      reg: route('register'),
      pass: route('password.request'),
      showForm: false,
      reqPass: false,
      authUser: window.authUser,
      showComplete: false,
      login: {
        username: "",
        password: "",
      },
      suggest: {
        name: "",
        selectedRegion: "",
        selectedSpecialty: "",
        selectedAverage: "",
        comment: "",
        file: "",
      },
      optionsLocal: [
        {
          text: "option 1",
          value: "1",
        },
        {
          text: "option 2",
          value: "2",
        },
        {
          text: "option 3",
          value: "3",
        },
        {
          text: "option 4",
          value: "4",
        },
      ],
      fileLabelDefault:
        "Submeter fotografia (Proporção Recomendada: 1080x1920)",
      fileLabel: "Submeter fotografia (Proporção Recomendada: 1080x1920)",
    };
  },
  methods: {
    callLogin() {
      this.showInitial = false;
      this.showLogin = true;
    },
    changePass() {
      this.reqPass = true;
    },    
    submitLogin() {
      // simulate on success login!!
      this.showLogin = false;
      this.showForm = true;
    },
    handleFileUpload() {
      if (this.$refs.file && this.$refs.file.files.length > 0) {
        console.log(this.$refs.file.files[0]);
        this.suggest.file = this.$refs.file.files[0];
        this.fileLabel = this.suggest.file.name;
      } else {
        this.fileLabel = this.fileLabelDefault;
      }
    },
    submitSuggest() {
      // Simulate Submit of form !!!
      this.showForm = false;
      this.showComplete = true;
    },
    submitAnother() {
      this.suggest = {
        name: "",
        selectedRegion: "",
        selectedSpecialty: "",
        selectedAverage: "",
        comment: "",
        file: "",
      };
      this.showComplete = false;
      this.showForm = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/_variables";
.suggest-restaurant {
  position: relative;
  z-index: 99999;
  padding-top: 30px;
  padding-bottom: 0px;

  .suggest-restaurant-header {
    margin-bottom: 60px;
  }
  .suggest-restaurant-header__title {
    font-size: 90px;
    line-height: 95px;
    text-transform: uppercase;
    font-family: "AzoSans-Light";
    color: $second-grey-color;

    span {
      font-family: "AzoSans-Bold";
    }

    @media (max-width: 1199.98px) {
      font-size: 36px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .suggest-restaurant-header__info {
    font-size: 20px;
    line-height: 20px;
    color: $primary-grey-color;
    max-width: 365px;
    align-self: center;

    @media (max-width: 1199.98px) {
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      margin-bottom: 20px;
      max-width: 100%;
    }
    span {
      font-family: "AzoSans-Bold";
      margin-top: 30px;
    }
  }

  .suggest-restaurant__complete-info {
    font-size: 20px;
    line-height: 20px;
    color: $primary-grey-color;
    align-self: center;
  }

  .suggest-restaurant__btn-login {
    border: none;
    font-family: "AzoSans-Bold";
    font-size: 20px;
    line-height: 20px;
    padding: 15px 0px;
  }

  .suggest-resturant__input--style {
    font-size: 20px;
    color: $primary-grey-color;

    &::placeholder {
      color: $third-grey-color;
    }
  }

  .suggest-restaurant__suggest {
    .suggest-restaurant-header__title {
      quotes: "“" "”";
      position: relative;

      &::after {
        position: absolute;
        font-family: sans-serif;
        content: close-quote;
        font-size: 360px;
        color: $primary-red;
        top: 100%;
        bottom: 0;
        left: 60%;

        @media (max-width: 1199.98px) {
          font-size: 144px;
          left: 85%;
        }
      }
    }

    .suggest-restaurant__suggest-info {
      font-size: 20px;
      line-height: 24px;
      color: $primary-grey-color;
      align-self: center;
      @media (max-width: 1199.98px) {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
      }
    }

    .suggest-restaurant__suggest-form {
      .suggest-resturant__input-file-upload {
        display: flex;
        @media (max-width: 1199.98px) {
          flex-direction: column;
        }
        input {
          display: none;
          visibility: hidden;
        }

        .suggest-resturant__input-file-upload-info {
          font-size: 20px;
          color: $primary-grey-color;
          background: $f2-grey-color;
          flex: 1;
          padding: 10px 30px;
          border-radius: 8px;
        }

        .suggest-resturant__input-file-upload-button {
          border: none;
          font-family: "AzoSans-Bold";
          font-size: 20px;
          line-height: 20px;
          padding: 15px 60px;
          background-color: $primary-grey-color;
          border-radius: 8px;
          margin-left: -10px;
          color: white;

          @media (max-width: 1199.98px) {
            margin-left: 0px;
            margin-top: -10px;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>