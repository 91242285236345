<template>
    <div class="quote" :class="[!verticalMode ? 'border-top border-bottom' : 'padding-vertical']">
        <div class="container">
            <div class="quote-container row" :class="[verticalMode ? 'col-12' : 'col-lg-10 offset-lg-2']">
                <div class="quote-user align-self-center" :class="[verticalMode ? 'col-12' : 'col-lg-4']">
                    <div class="quote-user-info row">
                        <div class="quote-user-image" :class="[verticalMode ? 'col-lg-12 col-5' : 'col-4']">
                            <img v-if="byUser.photo" class="img-fluid" :src="byUser.photo"/>
                        </div>
                        <div class="quote-user-data align-self-center" :class="[verticalMode ? 'col-lg-12 col-7' : 'col-8']">
                            <div class="column align-left">
                                <div class="quote-user-bywho">Sugestão mediador</div>
                                <div class="quote-user-name">{{ byUser.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="quote-text pt-lg-4 pt-2 align-self-center" :class="[verticalMode ? 'col-12 quote-margin-top' : 'col-lg-8']">
                    {{ quoteText }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Quote from user",
    
    props: {
        verticalMode: {
            type: Boolean,
            default: false
        },
        byUser: {
            type: Object,
            default: ''
        },
        quoteText: {
            type: String,
            default: ''
        },
    }
}
</script>
<style lang="scss" scoped>
@import "../styles/_variables";
.quote {

    .padding-vertical {
        padding: 60px 0px;
        @media (max-width: 1199.98px) {
             padding: 42px 0px;
        }
    }


    .quote-user-image {
            margin-bottom: 15px;
        img {
            object-fit: cover;
            width: 112px;
           max-width: 100%;
           height: 100%;
            border-radius: 112px;
                @media (max-width: 768px) {

    width: 80px;
                }
        }
    }

    .quote-user-bywho {
        font-family: 'AzoSans-Regular';
        font-size: 18px;
        line-height: 18px;
        color: $primary-grey-color;
        margin-bottom: 10px;
    }

    .quote-user-name {
        font-family: 'AzoSans-Bold';
        font-size: 25px;
        line-height: 25px;
        color: $primary-grey-color;

        @media (max-width: 1199.98px) {
            font-size: 21px;
            line-height: 21px;
        }
    }

    .quote-text {
        quotes: "“" "”";
       font-size: 15px;
    line-height: 21px;
        position: relative;

        &.quote-margin-top {
            margin-top: 35px;
        }

        @media (max-width: 1199.98px) {
            margin-top: 70px;
            font-size: 18px;
            line-height: 22px;
        }
    }
    .quote-text::before {
        position: absolute;
        font-family: sans-serif;
        content: open-quote;
        font-size: 110px;
        color: $primary-red;
    }

    .quote-container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
</style>