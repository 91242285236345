import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Restaurant from '../views/Restaurant.vue'
import RestaurantList from '../views/RestaurantList.vue'
import RestaurantListFid from '../views/RestaurantListFid.vue'
import Reservations from '../views/Reservations.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/restaurante/:id',
    name: 'Restaurant',
    component: Restaurant,
    props: true
  },
  {
    path: '/todos',
    name: 'RestaurantList',
    component: RestaurantList
  },
  {
    path: '/fidrest',
    name: 'RestaurantListFid',
    component: RestaurantListFid
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      console.log('hash');
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                var scrollDiv = document.querySelector(to.hash).offsetTop;
                resolve({el: to.hash, behavior: 'smooth',})
            }, 600)
        })
    }
    return { top: 0};
  }
})

export default router
