<template>
    <router-link class="text-decoration-none" :to="{ name: 'Restaurant', params:{ 'name': data.name, 'id': data.slug }}">
    <div class="reservations-item">
            <div class="reservations-item-image">
                <img class="img-fluid image" :src="data.image"/>
                <div class="reservations-item-flag-dog">
                    <div class="reservations-item-flag-title">
                        <div class="reservations-item-flag-title-month">
                            {{ data.month }}
                        </div>
                        <div class="reservations-item-flag-title-year">
                            {{ data.year }}
                        </div>
                    </div>
                    <span>
                        <IconDog/>
                    </span>
                </div>
            </div>
            <div class="row reservations-item_container">
                <div class="reservations-item-info align-left col-6 mt-3">
                    <div class="reservations-item-info_title">
                        {{ data.name }}
                    </div>
                </div>
                <div class="reservations-item-info_likes col-6 text-end">
                    <LikesCounter  minified :counter="data.likes"/>
                </div>
            </div>
    </div>
    </router-link>
</template>

<script>
import LikesCounter from "./LikesCounter.vue"
import IconDog from "./icons/FidDog.vue"
export default {
    name: "Restaurant Item",
    components: {
        LikesCounter,
        IconDog
    },
    props: {
        data: {
            type: Object,
            default: function () {
                return {};
            }
        },
    },
    data() { // This is only data for design! and test || TODO -- getting from service
        return {
            image: 'https://media-cdn.tripadvisor.com/media/photo-s/07/be/65/98/escondidinho-do-barredo.jpg',
            title: "Restaurante Lés-a-Lés",
            address: "Braga, Sé",
            phone: "966 000 000",
            likes: 1500
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/_variables";
.reservations-item-info_title{
    line-height: 26px;
    margin: 0;
    font-size: 18px;
    color: $primary-grey-color;
    font-family: "AzoSans-Regular";
    @media (max-width: 1199.98px) {
        font-size: 16px;
        line-height: 22px;
    }
}
.reservations-item {
    .reservations-item-image {
        position: relative;
        overflow: hidden;

        img {
            transform: scale(1.1);
            object-fit: cover;
            max-height: 175px;
            width: 100%; // TODO -- Validate this

            @media (max-width: 767.98px) {
                 height: 250px;
            }
        }

        .reservations-item-title {
            position: absolute;
            left: 20px;
            bottom: 40px;
            font-family: 'AzoSans-Bold';
            font-size: 22px;
            line-height: 22px;
            color: white;

            @media (max-width: 1199.98px) {
                font-size: 16px;
                line-height: 16px;
            }
        }

        .reservations-item-flag-dog {
            position: absolute;
            top: 0;
            right: 5%;
            background-color: $primary-red;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;

            span {
                svg {
                    width: 22px;
                }
            }

            .reservations-item-flag-title {
                &,
                div{

                font-size: 14px;
                line-height: 14px;
                color: white;
                }
                padding: 10px;

                .reservations-item-flag-title-month {
                    font-family: 'AzoSans-Bold';
                }
            }
        }
    }

    .reservations-item_container {
        align-items: center;
    }

    .reservations-item-info_address {
        font-family: 'AzoSans-Bold';
        font-size: 22px;
        line-height: 22px;
        color: $primary-grey-color;
        margin-bottom: 10px;
        @media (max-width: 1199.98px) {
            font-size: 14px;
            line-height: 14px;
        }
    }

    .reservations-item-info_phone {
        a {
            font-family: 'AzoSans-Regular';
            font-size: 20px;
            line-height: 20px;
            color: $primary-grey-color;
            text-decoration: none;
            @media (max-width: 1199.98px) {
                font-size: 14px;
                line-height: 14px;
            }
        }
    }

    .reservations-item-info {
        margin-top: 10px;
        margin-bottom: 10px;
        @media (max-width: 1199.98px) {
            margin-top: 15px;
            margin-bottom: 20px;
        }
    }
}
</style>