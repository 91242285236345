<template>
  <footer class="footer">
    <div class="container">
      <div class="row justify-content-center mt-5 mb-4">
        <div class="col-12">
          <ul class="socials mb-2">
            <li class="socials__item">
              <a
                href="https://www.fidelidade.pt"
                target="_blank"
                class="socials__link"
              >
                <svg
                  id="Group_573"
                  data-name="Group 573"
                  xmlns="http://www.w3.org/2000/svg"
                  width="150.028"
                  height="25.475"
                  viewBox="0 0 150.028 25.475"
                >
                  <g
                    id="Group_8"
                    data-name="Group 8"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_4"
                      data-name="Path 4"
                      d="M19.464,477.279a1.916,1.916,0,1,0,1.916,1.916A1.938,1.938,0,0,0,19.464,477.279Z"
                      transform="translate(-7.528 -476.129)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_5"
                      data-name="Path 5"
                      d="M17.338,478a5.384,5.384,0,0,0-1.229,3.987v2.149H14.22v2.63h1.889v9.944H19.2v-9.944h3.07v-2.63H19.2V481.7c0-1.2.282-2.119,1.662-2.119a8.156,8.156,0,0,1,1.738.23V477.2a6.233,6.233,0,0,0-2.019-.306A4.649,4.649,0,0,0,17.338,478Z"
                      transform="translate(-14.22 -476.899)"
                      fill="#e6041e"
                    />
                    <rect
                      id="Rectangle_8"
                      data-name="Rectangle 8"
                      width="3.093"
                      height="12.576"
                      transform="translate(10.378 7.255)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_6"
                      data-name="Path 6"
                      d="M29.027,484.2l-.048.024a6.68,6.68,0,0,0-9.357,6.236,6,6,0,0,0,6.033,6.342A5.846,5.846,0,0,0,29,495.78l.051.027v.714h3.066V476.991H29.027Zm0,9.124a5.012,5.012,0,0,1-2.733.869c-2.249,0-3.554-1.71-3.554-3.884a3.688,3.688,0,0,1,3.732-4.014,5.773,5.773,0,0,1,2.555.642Z"
                      transform="translate(-3.269 -476.712)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_7"
                      data-name="Path 7"
                      d="M31.1,479.2a6.367,6.367,0,0,0-6.414,6.6c0,3.938,2.964,6.545,7,6.545a9.223,9.223,0,0,0,4.6-1.175v-2.84a7.492,7.492,0,0,1-4.3,1.459,3.87,3.87,0,0,1-4.168-3.169H36.9a9.4,9.4,0,0,0,.076-1.256C36.98,481.32,34.222,479.2,31.1,479.2Zm-3.272,5.267a3.248,3.248,0,0,1,3.248-2.761,2.946,2.946,0,0,1,3.015,2.761Z"
                      transform="translate(6.998 -472.223)"
                      fill="#e6041e"
                    />
                    <rect
                      id="Rectangle_9"
                      data-name="Rectangle 9"
                      width="3.093"
                      height="19.53"
                      transform="translate(46.94 0.282)"
                      fill="#e6041e"
                    />
                    <rect
                      id="Rectangle_10"
                      data-name="Rectangle 10"
                      width="3.093"
                      height="12.576"
                      transform="translate(53.66 7.256)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_8"
                      data-name="Path 8"
                      d="M33.763,477.279A1.916,1.916,0,1,0,35.68,479.2,1.936,1.936,0,0,0,33.763,477.279Z"
                      transform="translate(21.458 -476.129)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_9"
                      data-name="Path 9"
                      d="M43.324,484.2l-.048.024a6.678,6.678,0,0,0-9.354,6.236,5.994,5.994,0,0,0,6.03,6.342A5.853,5.853,0,0,0,43.3,495.78l.051.027v.714h3.066V476.991H43.324Zm0,9.124a5.01,5.01,0,0,1-2.73.869c-2.252,0-3.554-1.71-3.554-3.884a3.686,3.686,0,0,1,3.732-4.014,5.76,5.76,0,0,1,2.552.642Z"
                      transform="translate(25.798 -476.712)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M49.625,488.55v-4.831a4.134,4.134,0,0,0-1.332-3.348,6.323,6.323,0,0,0-4.041-1.175,8.7,8.7,0,0,0-4.241,1.023v2.809a7.106,7.106,0,0,1,3.784-1.123,3.144,3.144,0,0,1,2.119.612,2.221,2.221,0,0,1,.618,1.713v.742a10.6,10.6,0,0,0-2.431-.281,6.354,6.354,0,0,0-3.527.869A3.564,3.564,0,0,0,38.96,488.6a3.468,3.468,0,0,0,1.408,2.888,4.918,4.918,0,0,0,2.915.845,6.035,6.035,0,0,0,3.729-1.2h.051a3.137,3.137,0,0,0,2.585,1.147,3.706,3.706,0,0,0,1.532-.306v-2.431a1.611,1.611,0,0,1-.69.179C49.876,489.724,49.625,489.24,49.625,488.55Zm-3.094.563a4.5,4.5,0,0,1-2.482.766c-1.1,0-1.995-.484-1.995-1.508,0-1.226,1.256-1.638,2.531-1.638a8.088,8.088,0,0,1,1.946.257Z"
                      transform="translate(36.031 -472.221)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_11"
                      data-name="Path 11"
                      d="M52.907,484.2l-.048.024a6.68,6.68,0,0,0-9.357,6.236,6,6,0,0,0,6.033,6.342,5.853,5.853,0,0,0,3.348-1.023l.051.027v.714H56V476.991H52.907Zm0,9.124a5.01,5.01,0,0,1-2.73.869c-2.252,0-3.557-1.71-3.557-3.884a3.688,3.688,0,0,1,3.735-4.014,5.76,5.76,0,0,1,2.552.642Z"
                      transform="translate(45.256 -476.712)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_12"
                      data-name="Path 12"
                      d="M54.979,479.2a6.367,6.367,0,0,0-6.411,6.6c0,3.938,2.964,6.545,7,6.545a9.211,9.211,0,0,0,4.6-1.175v-2.84a7.492,7.492,0,0,1-4.3,1.459,3.867,3.867,0,0,1-4.165-3.169h9.075a9.938,9.938,0,0,0,.076-1.256C60.861,481.32,58.1,479.2,54.979,479.2Zm-3.269,5.267a3.245,3.245,0,0,1,3.245-2.761,2.944,2.944,0,0,1,3.015,2.761Z"
                      transform="translate(55.546 -472.223)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_13"
                      data-name="Path 13"
                      d="M55.608,482.219a1.993,1.993,0,1,0,1.992,1.992A1.993,1.993,0,0,0,55.608,482.219Z"
                      transform="translate(65.795 -466.087)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_14"
                      data-name="Path 14"
                      d="M62.608,479.2a5.8,5.8,0,0,0-3.351,1.02l-.051-.024v-.714H56.139v18.2h3.094v-5.906l.048-.024a6.609,6.609,0,0,0,9.333-6.06C68.614,481.775,65.9,479.2,62.608,479.2Zm-.769,10.556a5.557,5.557,0,0,1-2.606-.687v-6.29a4.371,4.371,0,0,1,2.682-.92c2.2,0,3.581,1.789,3.581,4.014A3.593,3.593,0,0,1,61.839,489.752Z"
                      transform="translate(70.924 -472.202)"
                      fill="#e6041e"
                    />
                    <path
                      id="Path_15"
                      data-name="Path 15"
                      d="M66.09,478.005,63,478.541v3.351H60.8v2.634H63v5.391a5.022,5.022,0,0,0,1.02,3.605,4.039,4.039,0,0,0,3.07,1.226,5.577,5.577,0,0,0,2.555-.587v-2.634a4.177,4.177,0,0,1-1.892.539c-1.4,0-1.659-1.178-1.659-2.582v-4.958h3.551v-2.634H66.09Z"
                      transform="translate(80.387 -474.64)"
                      fill="#e6041e"
                    />
                  </g>
                </svg>
              </a>
            </li>
            <li class="socials__item">
              <a
                href="https://www.instagram.com/fidelidade.seguros/"
                target="_blank"
                class="socials__link"
              >
                <img
                  id="lnkinstagramhome"
                  src="../images/instagram-icon.svg"
                  width=" 18"
                  height="18"
                  alt="instagram"
                  class="instagram_icon"
                />
              </a>
            </li>
            <li class="socials__item">
              <a
                href="https://www.facebook.com/FidelidadeSeguros.Portugal/"
                target="_blank"
                class="socials__link"
              >
                <img
                  id="lnkfacebookhome"
                  src="../images/facebook-icon.svg"
                  width="18"
                  height="18"
                  alt="facebook"
                  class="facebook_icon"
                />
              </a>
            </li>
            <li class="socials__item">
              <a
                href="https://www.youtube.com/user/SegurosFidelidade"
                target="_blank"
                class="socials__link"
              >
                <img
                  id="lnkyoutubehome"
                  src="../images/youtube-icon.svg"
                  width=" 18"
                  height="18"
                  alt="youtube"
                  class="youtube_icon"
                />
              </a>
            </li>
            <li class="socials__item">
              <a
                href="https://www.linkedin.com/company/fidelidade/"
                target="_blank"
                class="socials__link"
              >
                <img
                  id="lnklinkedinhome"
                  src="../images/linkedin-icon.svg"
                  width=" 18"
                  height="18"
                  alt="linkedin"
                  class="linkedin_icon"
                />
              </a>
            </li>
          </ul>

          <p class="sec">
            Fidelidade - Companhia de Seguros, S.A. - NIPC e Matrícula 500 918
            880, na CRC Lisboa - Sede: Largo do Calhariz, 30, 1249-001 Lisboa -
            Portugal<br />
            Capital Social EUR 509.263.524 - www.fidelidade.pt – Linha de Apoio
            ao Cliente: T. 808 29 39 49 - E. apoiocliente@fidelidade.pt<br />
            Atendimento telefónico personalizado nos dias úteis das 8h às 23h e
            sábados das 8h às 20h.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
@import "../styles/_variables";
 
</style>