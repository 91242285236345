<template>
    <div class="slider-restaurants white-separator-margin pt-0">
        <div class="tira"></div>
        <div class="container position-relative">
            <img class="migalhas" :src="migalhas"/>
            <div class="row position-relative z-99 pt-5">
                  <div class="slider-restaurants-title d-block d-lg-none">
                    TOP <span>5</span>
                    
                </div>
                <div class="slider-restaurants-tabs col-lg-4 align-right d-none d-lg-block">
                      <div class="slider-restaurants-title">
                    TOP <span>5</span>
                </div>
                    <ul class="nav flex-column">
                        <li class="nav-item" :value="option" v-for="(option, index) in regions" :key="index" @click="onChangeRegion(option)">
                            <span class="nav-link" :class="{ 'active': option == selectedRegion}" aria-current="page">{{ option }}</span>
                        </li>
                    </ul>
                </div>
                <div class="slider-restaurants-select d-block d-lg-none">
                    <SelectCollapsible
                        :options="regions"
                        :selected="selectedRegion"
                        @select="onChangeRegion"
                    ></SelectCollapsible>
                </div>
                <div class="slider-restaurants-slider col-lg-8">
                    <div class="slider-restaurants-slider__pagination row">

                        <div class="col-4 slider-restaurants-slider__pagination-item-parent" v-for="(pageNumber, index) in pages" :key="index">
                        <div class="slider-restaurants-slider__pagination-item " :class="{ 'active': pageNumber == 1}">
                            {{ pageNumber }}
                        </div>
                        </div>
                    </div>
                    <div class="slider-restaurants-slider__list">
                        <div class="row">
                            <div class="slider-restaurants-slider__list-item" :class="[isMobileVersion ? 'col-6': 'col-4']" v-for="(item, index) in sliderItemsList" :key="index">
                                <RestaurantItem :data="item"/>
                            </div>
                        </div>
                    </div>
                    <div class="slider-restaurants-slider__left-arrow" @click="onChangePage('prev')"><LeftArrow/></div>
                    <div class="slider-restaurants-slider__right-arrow" @click="onChangePage('next')"><RightArrow/></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeftArrow from "./icons/LeftArrow.vue"
import RightArrow from "./icons/RightArrow.vue"
import RestaurantItem from './RestaurantItem.vue'
import SelectCollapsible from "../components/SelectCollapsible.vue";
import axios from 'axios'
export default {
    name: 'Slider Restaurants',
    components: {
        RestaurantItem,
        LeftArrow,
        RightArrow,
        SelectCollapsible
    },
    data() { // This is only data for design! and test || TODO -- getting from service
        return {
            regions: [],
            selectedRegion: '',
            sliderItemsList: [],
            pages: [],
            currentPage: 1,
            totalPager: 0,
            isMobileVersion: false,
            data: [],
            migalhas : require('../images/Migalhas.png').default
        }
    },
    mounted() {
        //  call fetch Data function to get values! This is an Example
        this.fetchData();

    },
    methods: {
        fetchData() {

                axios.get(route('top-5')).then(response => {
                    const json_data = response.data;
                    this.restaurants = json_data.restaurants;
                    this.regions = json_data.regions;
                    this.selectedRegion = this.selectedRegion ? this.selectedRegion : json_data.regions[0];
                    this.data = json_data.restaurants[this.selectedRegion];
                    this.sliderItems();
                    this.onResize()
                    window.addEventListener('resize', this.onResize, { passive: true })
                });
        },
         // Function on change region!
        onChangeRegion(value) {
            this.selectedRegion = value;
            this.fetchData();
        },

        onChangePage(value) {
            var numberOfItems = this.isMobileVersion ? 2 : 3;

            if(value == 'prev' && this.currentPage > 1) {
                this.currentPage--;
            }

            if(value == 'prev' && this.currentPage >= 1) {
                this.sliderItemsList = this.data.slice(this.currentPage - 1, this.currentPage + numberOfItems - 1);
                this.pages = this.pages.slice(this.currentPage - 1, this.currentPage + numberOfItems - 1);
            } else if (value == 'next' && this.currentPage >= 1 && this.currentPage + numberOfItems <= this.totalPager) {
                this.sliderItemsList = this.data.slice(this.currentPage, this.currentPage + numberOfItems);
                this.pages = this.pages.slice(this.currentPage, this.currentPage + numberOfItems);
                this.pages.push(this.currentPage + numberOfItems);
            }

            if(value == 'next' && this.currentPage + numberOfItems <= this.totalPager) {
                this.currentPage++;
            }

            if(this.currentPage > this.pages[this.pages.length -1]) {
                this.pages = [];
                for(var i = this.currentPage; i < (this.currentPage + numberOfItems); i++) {
                    this.pages.push(i);
                }
            } else if (this.currentPage < this.pages[0]) {
                this.pages = [];
                for(var i = this.currentPage; i < (this.currentPage + numberOfItems); i++) {
                    this.pages.push(i);
                }
            }
        },

        sliderItems() {
            var res = [];
            var counter = 1;
            var numberOfItems = this.isMobileVersion ? 2 : 3;
            this.pages = [];

            res = this.data.slice(0, numberOfItems);

            for(var i = 1; i <= numberOfItems; i++) {
                this.pages.push(i);
            }


            this.sliderItemsList = res;
            this.totalPager = this.data.length;
        },

        onResize() {
            this.isMobileVersion = window.innerWidth < 1199.98;
            this.sliderItems();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/_variables";

.slider-restaurants {
    position:relative;
    padding-bottom: 70px;
    margin-top: 0px;
    .z-99{
        z-index: 99;
    }

    .aprov {
        font-family: 'AzoSans-Bold';
        font-size: 30px;
        line-height: 30px;
        color: $primary-grey-color;

    }
    img.migalhas{
        position: absolute;
        left: -600px;
        top: -140px;
        z-index: 1;

        @media (max-width: 1199.98px) {
            left: -290px;
            top: -257px;
        }
    }
    @media (max-width: 1199.98px) {
        padding-bottom: 70px;

        .slider-restaurants-slider__list {
            max-width: 92%;
            margin: 0 auto;
        }
    }

    .slider-restaurants-tabs {
        @media (min-width: 768px) {
            padding-right: 120px;
        }


        .nav {
             span {
                cursor: pointer;
                font-size: 25px;
                line-height: 35px;
                text-transform: uppercase;
                font-family: 'AzoSans-Light';
                color: $primary-grey-color;
    display: inline-block;
                @media (max-width: 1199.98px) {
                    font-size: 26px;
                    line-height: 26px;
                    text-align: center;
                }
                padding: 0px;
                margin-bottom: 20px;
                &.active {
                    border-bottom: 6px solid $primary-red;
                    font-family: 'AzoSans-Bold';
                    @media (max-width: 1199.98px) {
                        border-bottom: 7px solid $primary-red;
                    }
                }
            }
        }
    }

    .slider-restaurants-title {
        &,
        span{
        font-size: 60px;
        line-height: 60px;
        text-transform: uppercase;
        font-family: 'AzoSans-Light';
        color: $primary-grey-color;
    margin-bottom: 30px;
            @media (max-width: 1199.98px) {
                font-size: 50px;
                line-height: 50px;
                margin-bottom: 10px;
            }
        }
        span {
            font-family: 'AzoSans-Bold';
        }

        @media (max-width: 1199.98px) {
            font-size: 50px;
            line-height: 50px;
            text-align: center;
        }
    }

    .slider-restaurants-slider {
        position: relative;

        .slider-restaurants-slider__left-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            margin-left: -30px;
            left: 0;
            @media (max-width: 1199.98px) {
                margin-left: 0px;
            }
        }
        .slider-restaurants-slider__right-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            margin-right: -30px;
            right: 0;
            @media (max-width: 1199.98px) {
                margin-right: 0px;
            }
        }
    }

    .slider-restaurants-slider__pagination {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        margin-bottom: 50px;
        .slider-restaurants-slider__pagination-item-parent{
            display: flex;
            justify-content: center;
        }
        .slider-restaurants-slider__pagination-item {
            background-color: $primary-grey-color;
            color: white;
            font-family: 'AzoSans-Bold';
            font-size: 34px;
            line-height: 34px;
            height: 55px;
            width: 55px;
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 55px;
            z-index: 2;
            margin: 15px;
             @media (max-width: 768px) {
                font-size: 24px;
                line-height: 24px;
                height: 45px;
                width: 45px;
             }

            &.active {
                height: 70px;
                margin: 0px;
                width: 70px;
                background-color: $primary-red;
                  @media (max-width: 768px) {
                   
                    height: 60px;
                    width: 60px;
                }
            }
        }

        &::before{
            content: '';
            border: 1px solid $primary-grey-color;
            position: absolute;
            width: 100%;
            z-index: 0;
        }
    }

    .slider-restaurants-select {
        background: white;
        margin-bottom: 30px;
        margin-top: 30px;
        border-top: 1px solid $primary-grey-color;
    }
}


</style>