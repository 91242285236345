<template>
  <div class="restaurant-page">
    <div class="border-bottom">
      <div class="container">
        <div class="row restaurant-page__region align-items-center">
          <div class="restaurant-page__region-back-btn col-md-8 col-6">
            <router-link class="btn-fid-grey btn-back" :to="{ name: 'Home' }">
              Voltar
            </router-link>
          </div>
          <div class="restaurant-page__region-info col-md-4 col-6 ps-md-5">
            {{ restaurant.region }}
          </div>
        </div>
      </div>
    </div>
    <div class="border-bottom">
      <div class="container">
        <div class="row restaurant-page__info-container" :class="restaurant.month?'top-month':''">
          <div class="restaurant-page__container-title col-lg-7 offset-lg-1">
            <div class="special-bottom-line d-lg-none"></div>
            <div class="row">
              <div class="restaurant-page__info-top col-12">
                <LikesCounter minified :counter="likes"></LikesCounter>
                <ApproveBtn :approveStatus="approveStatus" @update="actionApprove"></ApproveBtn>
              </div>
              <div class="restaurant-page__info-title col-12 col-md-10 mt-5">
                {{ restaurant.name }}
              </div>
              <div class="restaurant-item-flag-dog" v-if="restaurant.fidrest">
                <div class="restaurant-item-flag-title">
                  <div class="restaurant-item-flag-title-year text-start">
                    FID
                  </div>
                  <div class="restaurant-item-flag-title-year text-start">
                    REST
                  </div>
                </div>
                <span>
                  <IconDog />
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 restaurant-page__info ps-lg-5">
            <div class="row">
              <div class="restaurant-page__info-address col-12">
                {{ restaurant.addresssec }}
              </div>
              <div class="restaurant-page__info-subaddress col-12">
                {{ restaurant.address }}
              </div>
              <a href="tel:{{ restaurant.phone }}" v-if="restaurant.phone" class="restaurant-page__info-phone col-12">
                <span class="restaurant-page__info-phone-icon">
                  <PhoneIcon />
                </span>{{ restaurant.phone }}</a>
              <div v-if="restaurant.laboral" class="restaurant-page__info-schedule col-12">
                <span class="restaurant-page__info-schedule-icon">
                  <ClockIcon />
                </span>
                <div class="restaurant-page__info-schedule-info">
                  {{ restaurant.laboral }}
                </div>
              </div>
              <div v-if="restaurant.site" class="restaurant-page__info-website col-12 text-center text-md-start">
                <a :href="restaurant.site" class="Url_site" target="_blank">
                  Visitar website</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-bottom">
      <div class="container">
        <div class="restaurant-page__specialties row align-items-center">
          <div class="
              restaurant-page__specialties-title restaurant-page_25-text-b
              col-7 col-lg-7
              offset-lg-1
            ">
            Especialidades
          </div>
          <div class="
              restaurant-page__specialties-info restaurant-page_25-text
              col-5 col-lg-3
              ps-md-5
            ">
            {{ restaurant.specialty }}
          </div>
        </div>
      </div>
    </div>
    <div class="border-bottom">
      <div class="container">
        <div class="restaurant-page__price row align-items-center">
          <div class="
              restaurant-page__price-title restaurant-page_25-text-b
              col-7 col-lg-7
              offset-lg-1
            ">
            Média de Preço por pessoa
          </div>
          <div class="
              restaurant-page__price-info restaurant-page_25-text
              col-5 col-lg-3
              ps-md-5
            ">
            {{ restaurant.avgcost }}
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="restaurant-page__images row">
        <div class="restaurant-page__images-first col-lg-8 pe-lg-5">
          <img class="img-fluid" :src="imageContent" v-if="restaurant.images" />
        </div>
        <div class="restaurant-page__images-gallery col-lg-4 ps-lg-5">
          <div class="restaurant-page__images-gallery-item" v-for="(image, index) in restaurantImages" :key="index"
            @click="selectImage(image)">
            <div class="restaurant-page__more-images" @click="moreImagesCallback"
              v-if="moreImages && index == restaurantImages.length - 1">
              <img class="img-fluid" :src="image" />
              <button class="restaurant-page__more-btn btn-fid-red">
                <PlusIcon />
              </button>
            </div>
            <div v-else>
              <img class="img-fluid" :src="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-top">
      <div class="container">
        <div class="restaurant-page__fidrest row">
          <div :class="(restaurant.fidrest)?'col-lg-7 offset-lg-1 border-right with-fid':'col-12 text-center without-fid'" class="restaurant-page__fidrest-info py-5">
            <div class="fidrest-title text-start mb-3">
              Fid<span class="f-bold t-red">rests</span>
            </div>
            Troque as suas FidCoins por vouchers<br> e use-as nos FidRests aderentes.
          </div>
          <div v-if="restaurant.fidrest" class="restaurant-page__fidrest-fid col-lg-4 p-5">
            <strong class="d-block">Quer visitar este restaurante?</strong> Troque as suas FidCoins na<br> MyFidelidade.  
            <div class="restaurant-page__info-website col-12 text-center text-md-start fidcoinslink">
                <a href="" class="Url_site" target="_blank">Trocar FidCoins</a>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="restaurant.month" class="border-top border-bottom">
      <div class="container">
        <div class="restaurant-page__content-rest row">
          <div class="restaurant-page__content col-12 col-lg-7 offset-lg-1 pe-md-5">
            <div class="restaurant-page__content-title">
             
              <span>Na mesa do crítico</span>
            </div>
            <div class="restaurant-page__content-info">
              <p v-html="$filters.withBrTags(restaurant.month.text)"></p>
              <br />
              <strong>{{ restaurant.month.author }}</strong>
              <p>{{ restaurant.month.author_job }}</p>
            </div>
          </div>
          <div class="restaurant-page__content-author col-12 col-lg-4 ps-md-5 vertical">
            <Quote v-if="restaurant.auth" :byUser="restaurant.auth" :quoteText="restaurant.comment" verticalMode>
            </Quote>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!restaurant.month" class="">

      <Quote v-if="restaurant.auth" :byUser="restaurant.auth" :quoteText="restaurant.comment" horizontalMode></Quote>

    </div>
    <div class="mb-100 mt-5 container">
      <div class="restaurant-page__content-btns row gx-3 gy-3">
        <div class="col-lg-5 offset-lg-1 mx-auto">
          <router-link class="btn-fid-red btn-content" :to="{ name: 'Home' }">
            Voltar à página principal
          </router-link>
        </div>

      </div>
    </div>
    <ImageFull class-extra="parsley-back" :imageUrl="imageUrlBottom" />
  </div>
</template>
<script>
  import ClockIcon from "../components/icons/ClockIcon.vue";
  import PhoneIcon from "../components/icons/PhoneIcon.vue";
  import LikesCounter from "../components/LikesCounter.vue";
  import ApproveBtn from "../components/ApproveBtn.vue";
  import Quote from "../components/Quote.vue";
  import ImageFull from "../components/ImageFull.vue";
  import PlusIcon from "../components/icons/PlusIcon.vue";
  import IconDog from "../components/icons/FidDog.vue";
  import axios from "axios";
  import VueCookies from 'vue-cookies';
  export default {
    name: "Restaurant Page",
    props: {
      name: {
        type: String,
        default: "",
      },
      id: {
        type: String,
        default: "",
      },
    },
    components: {
      LikesCounter,
      Quote,
      ImageFull,
      PhoneIcon,
      ClockIcon,
      PlusIcon,
      IconDog,
      ApproveBtn,
    },
    data() {
      return {
        restaurant: {},
        restaurantImages: [],
        moreImages: false,
        likes: 0,
        approveStatus: false,
        imageUrlBottom: require("../images/image-bottom-rest.jpeg").default,
        imageContent: "",
      };
    },
    mounted() {
      //  call fetch Data function to get values!
      this.fetchData();
    },
    methods: {
      fetchData() {

        if (!VueCookies.get('anomid')) {
          VueCookies.set('anomid', Date.now() + '' + Math.random(), "365days");
        }
        var anon = false;
        var id = false;
        if (!window.authUser) {
          anon = VueCookies.get('anomid');
        } else {
          id = window.authUser.id;
        }
        axios
          .get(route("rest", { id: this.$route.params.id, anon: anon, user: id }))
          .then((response) => {
            this.restaurant = response.data;
            this.approveStatus = this.restaurant.approved;
            this.likes = this.restaurant.likes;
            this.imageContent =
              this.restaurant.images && this.restaurant.images.length > 0
                ? this.restaurant.images[0]
                : "";
            if (this.restaurant.images && this.restaurant.images.length > 3) {
              this.restaurantImages = this.restaurant.images.slice(0, 3);
              this.moreImages = true;
            } else {
              this.restaurantImages = this.restaurant.images;
              this.moreImages = false;
            }
          });
      },
      moreImagesCallback() {
        // Get more images
        this.restaurantImages = this.restaurant.images;
        this.moreImages = false;
      },
      selectImage(newImageurl) {
        this.imageContent = newImageurl;
      },
      actionApprove() {

        var anon = false;

        if (!window.authUser) {
          var id = false;
          anon = VueCookies.get('anomid');
        } else {
          var id = window.authUser.id;
        }
        // For adding the token to axios header (add this only one time).
        var token = document.head.querySelector('meta[name="csrf-token"]');
        window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
        axios.post(route("like"), { user: id, rest: this.restaurant.id, anon: anon }).then((response) => {
          this.approveStatus = response.data;
          if (this.approveStatus) {
            this.likes = this.likes + 1;
          } else {
            this.likes = this.likes - 1;
          }
        }).catch((error) => {
          console.log(error.response.data)
        });

      },
    },
  };
</script>
<style lang="scss" scoped>
@import "../styles/_variables";
  .restaurant-page__info-top {
    display: inline-flex;

    > div:last-child {
      margin-left: 20px;
    }
  }

  .restaurant-page__fidrest {


  }
  .restaurant-page__region {
    .restaurant-page__region-back-btn {
      padding-top: 10px;
      padding-bottom: 10px;
      border-right: 1px solid $primary-grey-color;

      @media (max-width: 768px) {
        font-size: 36px;
        line-height: 36px;
        border-right: none;
        padding-top: 28px;
        padding-bottom: 28px;
      }
    }

    .btn-back {
      border: none;
      text-decoration: none;
      padding: 17px 0px;
      font-family: "AzoSans-Black";
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      border-radius: 8px;
      display: block;
      text-align: center;
      max-width: 170px;

      @media (max-width: 768px) {
        max-width: 112px;
        padding: 12px 0px;
      }
    }

    .restaurant-page__region-info {
      font-family: "AzoSans-Light";
      color: $second-grey-color;
      font-size: 30px;
      line-height: 48px;
      margin-top: 12px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 27px;
        line-height: 40px;
      }
    }
  }

  .restaurant-page__info-container {
    .restaurant-page__container-title {
      position: relative;
      padding-top: 15px;
      padding-bottom: 0px;
      border-right: 1px solid $primary-grey-color;

      @media (max-width: 768px) {
        font-size: 36px;
        line-height: 40px;
      }

      @media (max-width: 771px) {

        border-right: none;
        border-bottom: 1px solid $primary-grey-color;
      }
    }

    .restaurant-page__info-title {
      font-family: "AzoSans-Bold";
      color: $second-grey-color;
      font-size: 45px;
      line-height: 50px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 34px;
        line-height: 36px;
      }
    }

    .restaurant-page__info {
      padding-top: 15px;
      padding-bottom: 15px;

      @media (max-width: 771px) {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .restaurant-page__info-address {
        font-family: "AzoSans-Bold";
        color: $second-grey-color;
        font-size: 28px;
        line-height: 28px;

        @media (max-width: 768px) {
          font-size: 21px;
          line-height: 28px;
        }
      }

      .restaurant-page__info-subaddress {
        font-family: "AzoSans-Regular";
        color: $second-grey-color;
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 10px;

        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 21px;
        }
      }

      .restaurant-page__info-phone {
        display: flex;
        align-items: center;
        font-family: "AzoSans-Bold";
        color: $second-grey-color;
        font-size: 19px;
        line-height: 21px;
        margin-bottom: 15px;

        .restaurant-page__info-phone-icon {
          width: 28px;
          height: 28px;
          align-self: center;
          background-color: $primary-red;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 28px;
          margin-right: 20px;
        }
      }

      .restaurant-page__info-schedule {
        display: flex;
        align-items: center;
        color: $second-grey-color;
        font-size: 19px;
        line-height: 21px;
        margin-bottom: 15px;

        .restaurant-page__info-schedule-icon {
          width: 28px;
          height: 28px;
          align-self: center;
          background-color: $primary-red;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 28px;
          margin-right: 20px;
        }

        .restaurant-page__info-schedule-info {
          span {
            font-family: "AzoSans-Bold";
          }
        }
      }
    }

    .restaurant-page__info-website {
      a {
        display: inline-block;
        font-family: "AzoSans-Bold";
        color: white;
        background-color: $primary-red;
        border-radius: 8px;
        text-decoration: none;
        font-size: 18px;
        line-height: 24px;
        padding: 13px 0;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        width: 200px;
        max-width: 300px;
        text-align: center;
      }
    }
  }

  .restaurant-page_25-text-b {
    font-family: "AzoSans-Bold";
    font-size: 22px;
    line-height: 42px;
    color: $second-grey-color;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .restaurant-page_25-text {
    font-family: "AzoSans-Regular";
    font-size: 22px;
    line-height: 22px;
    color: $second-grey-color;

    @media (max-width: 768px) {
      text-align: right;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .restaurant-page__price-info,
  .restaurant-page__price-title,
  .restaurant-page__specialties-info,
  .restaurant-page__specialties-title {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .restaurant-page__specialties-title,
  .restaurant-page__price-title {
    border-right: 1px solid $primary-grey-color;
  }

  .restaurant-page__images {
    .restaurant-page__images-first {
      padding-top: 25px;
      padding-bottom: 25px;
      border-right: 1px solid $primary-grey-color;

      @media (max-width: 768px) {
        padding-bottom: 0px;
        padding-top: 15px;
        border-right: none;
      }

      img {
        object-fit: cover;
        max-height: 530px;
        height: 530px;
        width: 100%; // TODO -- Validate this

        @media (max-width: 767.98px) {
          height: 250px;
        }
      }
    }

    .restaurant-page__images-gallery {
      display: flex;
      flex-direction: column;
      max-height: 530px;
      margin-top: 25px;
      overflow-y: scroll;

      @media (max-width: 768px) {
        margin-top: 20px;
        flex-direction: row;
        overflow-y: inherit;
        overflow-x: scroll;
        margin-bottom: 15px;
        margin-left: 0px;
        padding-left: 12px;
        padding-right: 25px;
      }

      .restaurant-page__images-gallery-item {
        padding-bottom: 17px;
        cursor: pointer;

        &:last-child {
          padding-bottom: 0;
        }

        img {
          object-fit: cover;
          max-height: 165px;
          width: 100%;

          @media (max-width: 1024px) {

            max-height: 120px;
          }
        }

        @media (max-width: 768px) {
          min-width: calc(100% / 3);
          padding-bottom: 10px;
          margin-right: 1%;

          &:last-child {
            padding-bottom: 10px;
          }

          img {
            height: 100%;
          }
        }

        .restaurant-page__more-images {
          position: relative;
          overflow: hidden;

          img {
            filter: blur(5px);
            transform: scale(1.1);
            position: relative;
            z-index: 1;
          }

          .restaurant-page__more-btn {
            width: 73px;
            height: 73px;
            border: none;
            border-radius: 73px;
            position: absolute;
            top: 0;
            z-index: 2;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }

  .restaurant-page__content {
    padding-top: 25px;
    padding-bottom: 25px;
    border-right: 1px solid $primary-grey-color;

    @media (max-width: 768px) {
      border-right: none;
      border-bottom: 1px solid $primary-grey-color;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
    }

    .restaurant-page__content-title {
      padding-bottom: 15px;

      &,
      span {
        font-family: "AzoSans-Regular";
        color: $primary-grey-color;
        font-size: 40px;
        line-height: 45px;
        text-transform: uppercase;
      }

      @media (max-width: 768px) {
        padding-bottom: 60px;
        padding-left: 70px;
        position: relative;
        text-align: left;

        &,
        span {
          font-size: 30px;
          line-height: 35px;
        }
      }

      span {
        display: block;
        color: $primary-red;
        font-family: "AzoSans-Light";
      }
    }

    .restaurant-page__content-info {
      font-family: "AzoSans-Regular";
      color: $primary-grey-color;
      font-size: 20px;
      line-height: 24px;

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  .restaurant-page__content-author {
    padding-top: 25px;
    padding-bottom: 25px;

    @media (max-width: 768px) {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $second-grey-color;
    }
  }

  .restaurant-page__content-btns {
    .btn-content {
      text-align: center;
      font-family: "AzoSans-Bold";
      font-size: 16px;
      line-height: 18px;
      padding: 13px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
.restaurant-page__fidrest-fid{
  &,
  strong{
    font-size: 18px;
    line-height: 22px;
  }
}
  .fidrest-title {
  &,
  span {
    font-family: "AzoSans-Light";
    font-size: 42px;
    line-height: 42px;
    text-transform: uppercase;
    text-align: right;
  }
  color: $second-grey-color;

  span {
    color: $primary-red;
    font-family: "AzoSans-Bold";
  }
}
.with-fid{

  font-size: 18px;
    line-height: 22px;
  display: flex;
    flex-direction: column;
    justify-content: center;
}
.without-fid{

  font-size: 18px;
    line-height: 22px;
  display: flex;
  .fidrest-title{
    margin-bottom: 0px !important;
    margin-right: 30px;
  }
  align-items: center;
    justify-content: center;
  br{
    display: none;
  }
}

.fidcoinslink a{
        display: inline-block;
        font-family: "AzoSans-Bold";
        color: white;
        background-color: $primary-red;
        border-radius: 8px;
        text-decoration: none;
        font-size: 18px;
        line-height: 24px;
        padding: 13px 0;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        width: 200px;
        max-width: 300px;
        text-align: center;
        margin-top: 10px;
      }

</style>