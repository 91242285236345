<template>
<div class="header-container-min container-fluid col-xs-12">
    <div class="header-logo">
        <router-link :to="{ name: 'Home'}"><img class="img-fluid" :src="headerLogoUrl"/></router-link>
    </div>
</div>
</template>
<script>
export default {
    name: 'Header Min Mode',
    data() {
        return {
            headerLogoUrl: require('../images/fidelidade-since-logo.png').default
        }
    }
};
</script>
<style lang="scss" scoped>
.header-logo {
    text-align: center;
    padding: 52px 0px;
}
.header-container-min {
    background: #2B2B2B url(../images/header-small@2x.png) no-repeat top;
    background-size: cover;
  //  box-shadow: inset 0 0 150px #000000;
    display: flex;
    flex-direction: column;

    .header-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-evenly;
    }
}
</style>