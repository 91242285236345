<template>
    <div class="approve-btn align-left" :class="approveStatus?'approved':'toapprove'">
        <div class="approve-btn-container" @click="approveAction">
            <div class="approve-btn-icon"><IconDog/></div>
            <div class="approve-btn-text">
                <span v-if="!approveStatus">Aprovar</span>
                <span v-if="approveStatus">Aprovado</span>
            </div>
        </div>
    </div>
</template>
<script>
import IconDog from "./icons/FidDogRed.vue"

export default {
    name: "Approve btn",
    components: {
        IconDog
    },
    props: {
        counter: {
            type: Number,
            default: 0
        },
        minified: {
            type: Boolean,
            default: false
        },     
        approveStatus: {
            type: Boolean,
            default: false
        }
    },
    data() { // This is only data for design! and test || TODO -- getting from service
        return {
        }
    },
    methods: {
        approveAction() {
            this.$emit('update');
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../styles/_variables";

.approve-btn {

             
    .approve-btn-container {
        text-align: left;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        .approve-btn-text{
            h4 {
                line-height: 27px;
                margin: 0;
                font-size: 27px;
                color: $primary-grey-color;
                @media (max-width: 1199.98px) {
                    line-height: 21px;
                    margin: 0;
                    font-size: 21px;
                }
            }
            span {
                font-family: 'AzoSans-Light';
                line-height: 18px;
                margin: 0;
                font-size: 18px;
                color: $primary-grey-color;
                @media (max-width: 1199.98px) {
                    line-height: 12px;
                    margin: 0;
                    font-size: 12px;
                }
            }
        }
    }
    .approve-btn-icon {
        display: flex;
        width: 45px;
        height: 45px;
        background: white;
        border: 1px solid $primary-red;
        justify-content: center;
        align-items: center;
        border-radius: 45px;
        margin-right: 15px;

        @media (max-width: 1199.98px) {
            width: 35px;
            height: 35px;
            margin-right: 10px;
        }

        svg {
            width: 18px;
            height: auto;
        }


    }
}
</style>